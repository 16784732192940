body {
  margin: 0;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* default MUI outlined inputs are too high */
input.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 10.5px 14px;
}
label.MuiInputLabel-outlined {
    transform: translate(14px, 12px) scale(1);
}

.matrix  td {
  text-align: center;
  height: 36px;
  width: 150px;
  font-weight: bold;
  background-color: #1976d2;
  color: #ffffff;
}
